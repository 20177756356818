.spinner {
    border: 4px solid rgba(255, 255, 255, 0.493);
    width: 9px;
    height: 9px;
    border-radius: 50%;
    border-left-color: #09f;
    display: inline-block;
    position: relative;
    top: 3px;
    left: 5px;
    margin: 0 5px 0 0;
    animation: spin 1s ease infinite;
}

  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.tag-option {
    padding: 10px 0;
    font-size: 16px
}

.tag-option:hover {
    background-color: dodgerblue;
    color: white;
}