.RCFullWidth, .RCWithSidear {
    .MuiGrid-item {
        & :hover {
            z-index: 2;
        }
        z-index: 1;
    }
}

.dam-item {
    display: flex;
    flex-direction: column;
    margin: auto auto 15px auto;
    overflow: hidden;
    background-color: #ffffff;
    max-height: 250px;
    cursor: pointer;
    // box-shadow: 0px 1px 1px 0px black;
    transition: all 0.15s ease-in-out;
    border-top: 2px solid $list_item_border_top;

    &.selectable {
        cursor: pointer;
    }

    &:hover {
        transform: scale(1.1);
        box-shadow: 0px 7px 10px 1px darkgrey;
    }

    > .dam-type {
        border-bottom: 1px solid $list_item_border_top;
        padding: 0 0 10px;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
    }
    
    > .dam-preview {
        > .dam-preview-img {
            height: $list_item_preview_height;
            width: inherit;
            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }

        > .dam-preview-title {
            border-top: 1px solid $list_item_border_top;
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 10px;
            // height: 40px;
            // > strong {
            //     top: 10px;
            //     position: relative;
            // }
        }
    }
}

.in-list {
    transition: all 0.15s ease-in-out;
    button {
        padding: 10px;
    }
    &:hover {
        transition: all 0.15s ease-in-out;
        transform: scale(1.1);
        box-shadow: 0px 7px 10px 1px darkgrey;
    }
}



.dam-item-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 6px auto;
    padding: 0;
    .xdam-btn-primary:last-of-type {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        margin-left: 1px;
    }
    .xdam-btn-primary:first-of-type {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-right: 1px;
    }
}

.striped {
    max-height: 120px;
    &:nth-of-type(odd) {
        background-color: #e8e8e8;
    }
}

.associated-files-card {
    padding: 10px; 
    margin: 10px 0;
}
.associated-files-card-pending-removal {
    background-color: #ffc4c3 !important;
}

.associated-files-card-pending-addition {
    background-color: #b3efac !important;
}

.remove-media-from-uploading-queue-button {
    color: red;
    cursor: pointer !important;
}

.cdn-form-textfield {
    margin-bottom: 15px !important;
    margin-right: 25px !important;
    margin-top: 15px !important;
}
