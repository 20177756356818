.suggestion-wrap {
    margin-top: 10px;
    border-left: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.suggestion-unit {
    font-size: 0.8em;
    font-weight: bold;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.suggestion-unit:hover {
    background-color: rgb(245, 245, 245);
}

.suggestion-unit span {
    margin-left: 5px;
    padding-right: 10px;
}

.suggestion-icon {
    display: flex;
    height: 100%;
    width: 20px;
    justify-content: center;
    align-items: center;

}

.suggestions-more {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 15px;
    text-align: right;
}

.suggestions-more span {
    cursor: pointer;
    color: teal;
    transition: color ease-out 0.3s;
}

.suggestions-more span:hover {
    color: black;
}