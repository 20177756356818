.header {
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px !important;
}

.groupButtons {
    justify-content: flex-end;
    gap: 15px;
}

.button {
    min-width: 100px;
}

.closeIcon {
    &:hover, &:active {
        background-color: transparent !important;
    }
}