//Color palett

$primary: #43A1A2; //Main buttons
$secondary: #214F61; //Header background

//To Check
$error: #E13144;
$warning: #F6AB0E;
$success: #4BA0A0;
$info: #5C9AD0;

$gray1: #EEE; //Body background
$gray2: #E0E0E0; //Sidebar background
$gray3: #ADADAD; //Borders


$headerHeight: 82px;

//extras
$list_item_bg_color: #fff;
$list_item_border_top: #b1b1b1;
$list_item_box_shadow: gray;
$list_item_preview_height: 120px;

