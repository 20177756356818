.MuiPaginationItem-page.Mui-selected {
    background-color: $primary !important;
    color: white;
}

.buttonStyle {
    background-color: $primary !important;
    color: white !important;
}

.ui.teal.button, .ui.teal.buttons  {
    background-color: $primary !important;
    .button {
        background-color: $primary;
    }
    color: white;
}

.ui.button {
    font-family: 'Roboto', 'Lato', sans-serif;
}
//facets headers

.cardOpen {
    & .MuiButtonBase-root {
        background-color: $primary !important;
        color: white !important;
    }
    & .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-fullWidth {
        .MuiButton-endIcon {
            color: white !important;
        }
    }
}


.facetCard {

    .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-fullWidth {
        border-radius: unset;
        background-color: $gray1;
        border-top: 2px solid $gray3;
        .MuiButton-endIcon {
            color: $gray3
        }
    }
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-1 {
        background-color: $primary;
        border-top: 2px solid $gray3;
        button {
            span {
                svg {
                    fill: white;
                }
            }
        }
    }
    
}


//end facets 


.MuiPagination-ul {
    li:first-of-type {
        button {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }
    li:last-of-type {
        button {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
    li {
        button {
            border: none;
            border-radius: unset;
            background-color: white;
        }
        .MuiPaginationItem-root {
            height: 32px;
            margin: 0;
            padding: 0;
            border-radius: unset;
            background-color: white;
        }
        .MuiPaginationItem-ellipsis {
            line-height: 2;
        }
    }
}