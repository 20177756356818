.relatedFiles-ul {
    list-style-type: none;
    a {
        cursor: pointer;
    }
}

.resource-metadata {
    p, strong {
        text-transform: capitalize;
        margin: 10px 0;
    }
    ul {
        list-style-type: none;
        li {
            color: #4183c4;
            padding: 5px 0;
            cursor: pointer;
            margin-right: 20px;
            border-bottom: 1px dashed gray;
        }
        li:first-of-type {
            border-top: 1px dashed gray;
        }
    }

}